<template>
  <div class="admin-wrap">
    <AdminMenu active-item="spots" />
    <div class="admin-main">
      <AdminHeader title="診間與休息區管理" backlink="javascript:history.back()" />
       <div class="office-detail">
        <div class="office-detail__basic-info">
          <div class="office-detail__basic-info-inner">
            <div class="office-detail__col">
              <h3>{{title}}</h3>
              <div class="office-detail__form">
                <div class="office-detail__row" v-if="spotId > 0">
                  <label for="sn">編號</label>
                  <input type="text" id="sn" v-model="spot.id"  disabled="disabled">
                </div>
                <div class="office-detail__row">
                  <label for="name">名稱</label>
                  <input type="text" id="name" v-model="spot.name">
                </div>
                <div class="office-detail__row">
                  <label for="status">分類</label>
                  <div class="office-detail__select-wrap">
                    <select name="type" id="status" v-model="spot.type">
                      <option value="0" disabled>類型</option>
                      <option value="ROOM" selected="selected">診間</option>
                      <option value="REST_AREA">休息區</option>
                    </select>
                  </div>
                </div>
                <div class="office-detail__row">
                  <label for="place">位置</label>
                  <input type="tel" id="place" v-model="spot.location">
                </div>
                <div class="office-detail__row">
                  <label for="status">狀態</label>
                  <div class="office-detail__select-wrap">
                    <select name="type" id="status" v-model="spot.status">
                      <option value="0" disabled>狀態</option>
                      <option value="AVAILABLE" selected="selected">可使用</option>
                      <option value="OCCUPIED">使用中</option>
                      <option value="FIXING">整修中</option>
                      <option value="CLOSED">不開放</option>
                    </select>
                  </div>
                </div>
                <div class="office-detail__row" v-if="qrcode.length > 0 && isShowQRCode">
                  <label for="qrcode">QRCode</label>
                  <div class="office-detail__avatar">
                    <div class="office-detail__picture-wrap">
                      <img :src="qrcode" alt="" @error="handleQRCodeError">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="office-detail__col">
              <h3></h3>
              <div class="office-detail__row">
                <label for="picture">照片</label>
                <div class="office-detail__avatar">
                  <div class="office-detail__picture-wrap">
                    <img :src="photo" alt="">
                  </div>
                  <input type="file" @change="handleImage" accept="image/*">
                </div>
              </div>
              <div class="office-detail__row">
                <label for="memo">說明</label>
                <textarea name="memo" id="memo" cols="30" rows="10" v-model="spot.photoDesc"></textarea>
              </div>
            </div>
          </div>
          <button class="admin-btn-lg" @click="onSave">儲存</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  apiGetSpot,
  spotPhotoUrl,
  apiSaveSpot,
  qrcodeUrl
} from '@/api/v1/admin'
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'

export default {
  name: 'SpotDetail',
  props: ['spotId'],
  components: { AdminMenu, AdminHeader },
  computed: {
    title () {
      return this.spotId > 0 ? '基本資料' : '基本資料（新增）'
    }
  },
  created () {
    this.fetchData()
  },
  data () {
    return {
      spot: {
        id: 0,
        name: '',
        type: 'ROOM',
        location: '',
        status: 'CLOSED',
        photoDesc: ''
      },
      photo: '',
      qrcode: '',
      isUploadPhoto: false,
      isShowQRCode: true
    }
  },
  methods: {
    fetchData () {
      if (this.spotId > 0) {
        apiGetSpot({ spotId: this.spotId }).then(data => {
          // console.log(data)
          if (data.error) {
            console.log(data.error.message)
          } else {
            this.spot = data
            this.qrcode = qrcodeUrl('spots', this.spot.id)
            this.loadPhoto()
          }
        })
      }
    },
    handleQRCodeError () {
      this.isShowQRCode = false
      console.log(`load qrcode ${this.photo} failed`)
    },
    loadPhoto () {
      if (this.spot.photo) {
        this.photo = spotPhotoUrl(this.spotId)
      }
    },
    handleImage (e) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.photo = e.target.result
        this.isUploadPhoto = true
      }
      reader.readAsDataURL(e.target.files[0])
    },
    onSave () {
      // TODO: verify input
      const params = JSON.parse(JSON.stringify(this.spot))
      params.spotId = this.spotId
      if (this.isUploadPhoto) {
        params.photoContent = this.photo
      }
      apiSaveSpot(params).then(data => {
        if (data.error) {
          this.$swal('儲存失敗', data.message, 'warning')
        } else {
          this.$swal('儲存完成', '', 'success').then(() => history.back())
        }
      })
    }
  }
}
</script>

<style>

</style>
